import React from 'react';
import { Flex } from 'blocjs';
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router,
} from 'react-router-dom';

import { ghLocationHost, ghBaseName, defaultBaseName } from './constants';
import { Navigation, Footer, Message } from './components';
import {
  Login,
  Messages,
} from './pages';

const uniqueKey = Date.now();

const Routes = ({ isAuthenticated }) => {
  const baseName = window.location.host === ghLocationHost ? ghBaseName : defaultBaseName;
  
  return (
    <Router basename={baseName}>
    <Flex flexWrap="wrap" flex={1} width={1} pb={172}>
      <Navigation authenticated={isAuthenticated} uniqueKey={uniqueKey}/>
      <Message />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />}/>
        <Route path="/login">
          <Login />
        </Route>
        <Protected path="/messages" isAuth={isAuthenticated}>
          <Messages />
        </Protected>
        <Redirect to="/page-not-found" />
      </Switch>
      <Footer />
    </Flex>
  </Router>
);
}
  

export const Protected = ({ isAuth, children, ...rest }) => {
  const handleRender = ({ location }) => {
    if (isAuth) return children;

    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  };
  
  return (
    <Route render={({ location }) => handleRender({ location })} {...rest} />
  );
};

export default Routes;

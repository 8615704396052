const users = [
    {
        "username": "z.iradukunda",
        "email": "z.iradukunda@irembo.com",
        "password": "U2FsdGVkX1/2VnlHfaiQ7SzCM+zIh0G3kSu/JCbSvk8IJcMQwM2FON1X/Nl/sVV93FVVYjDp5frmnPlri3MxgQ=="
    },
    {
        "username": "m.rutajogerwa",
        "email": "m.rutajogerwa@irembo.com",
        "password": "U2FsdGVkX1/yWR8myZjE7ldfQKSlZMS2608qCcpdehncXrrsvAiIwAJ4hC1APWoYgi8wQMOMKEBleS/pp4b1aQ=="
    },
    {
        "username": "dom.muhire",
        "email": "dom.muhire@irembo.com",
        "password": "U2FsdGVkX19zirn4MaWPgCk14Cr8z/7kFlRqhaLoZC32UW7KZNc8X6HHEq8e0S97bFAKJcFRqK0ihonOxnUOLg=="
    },
    {
        "username": "p.gategabondo",
        "email": "p.gategabondo@irembo.com",
        "password": "U2FsdGVkX1/PcNBveprDOeHs2q0HFM4Sl0Oi+JRmg1ClwJL2vsVDo2DZ/3STC0KANsCWsGB3FQRuFPtM87eYpQ=="
    },
    {
        "username": "l.nyinawinkindi",
        "email": "l.nyinawinkindi@irembo.com",
        "password": "U2FsdGVkX1/D9ZuWhVLDEdaQX5vaRb6+5MCCa/P3ni6ZTBwiG+zlVOBg8/3XdjjAE1TjeArcf3/3EFFAaTD2Mw=="
    },
    {
        "username": "p.ndjientcheu",
        "email": "p.ndjientcheu@irembo.com",
        "password": "U2FsdGVkX18wRs2j6yJscUdeu+JsUP7M55vH4vED/e6kovE5/lJpHmiMXBwu5TTrCQRUdW9CKi+y+b/th9BBWQ=="
    },
    {
        "username": "p.touko",
        "email": "p.touko@irembo.com",
        "password": "U2FsdGVkX1/X+m0Kyfki9A+Ns2eJvdi6ID3FU9WBJQuIsYWDpeHtrWui3zy9msKW"
    },
    {
        "username": "d.bayisabe",
        "email": "d.bayisabe@irembo.com",
        "password": "U2FsdGVkX18orErwd4TjimRf4O/BJSf37zVTtXxWNazKhhFhWsEnbru0ZBpIaleSUcMWac2v64uDKmKwWKC0UA=="
    },
    {
        "username": "a.kabuto",
        "email": "a.kabuto@irembo.com",
        "password": "U2FsdGVkX19Q0cz37qsP11CtH47VD0LdKGzjc4DuihMi9pLjGo0Y5FA/QekIzyMEGCA7LMHdArJHpafd6pChlw=="
    },
    {
        "username": "k.kabera",
        "email": "k.kabera@irembo.com",
        "password": "U2FsdGVkX1/PIKCcE9Cx1scAOiC5CC1GXrB5CYSIWH8xJT/ZBSFbtJc9+cS1DhjOFvZ+wzKD1YUDGiNL1G+YAQ=="
    },
    {
        "username": "nkurunzizadominique",
        "email": "nkurunzizadominique@gmail.com",
        "password": "U2FsdGVkX1/j4wW501ltmf53upJu4Ggu9iOgNKv8BKZW4HenvcTSZyI2gMWdy+dAgXcOhXm3MOCQSYtt1OtxWw=="
    },
    {
        "username": "rwakibibijohn",
        "email": "rwakibibijohn@gmail.com",
        "password": "U2FsdGVkX186MrXKAd5YHV4Emv076lp67tYfDRsJazZzQBQ5Vyz6lqV4iXpP3nErjUhNncSxWqsCDaMqtKKL9w=="
    },
    {
        "username": "rugaju1",
        "email": "rugaju1@gmail.com",
        "password": "U2FsdGVkX19ev8sQN5hoXPcLZZ9fLsoYawbjWdr6Tl7scNvivuNp7LguZvNfjgxu"
    },
    {
        "username": "drevcol",
        "email": "drevcol@police.gov.rw",
        "password": "U2FsdGVkX19EITHKr/noWcOrDDst3BKhCzHzfQThCiU9vEF+hGu/yeg/jrPqTIQSY4qqT86OBm1xkyzUoYaJZg=="
    },
    {
        "username": "cotfru",
        "email": "cotfru@police.gov.rw",
        "password": "U2FsdGVkX19RIOdd152LeEzQf3lhAIfqhEcXyPCW/sP+UG0UCQlJrL3nU+oBAG0hDFx0F7dJK+HJvyuBwvqdpA=="
    },
    {
        "username": "admintfru",
        "email": "admintfru@police.gov.rw",
        "password": "U2FsdGVkX1+zZ7tN7WNCXZf3yjl/E0HO/szmx+q3yPhLchAVHYYFoHkHuL9vNhB/vV/5aoCtljqdMmIidwzt+g=="
    },
    {
        "username": "gitego194",
        "email": "gitego194@gmail.com",
        "password": "U2FsdGVkX1/FGk2unbv90/24HGbvv6g+9gMOSbQqujTKh3Mh1CtotIfzd4Jmd9//"
    },
    {
        "username": "oliviernizeyimana170",
        "email": "oliviernizeyimana170@gmail.com",
        "password": "U2FsdGVkX1/IoalmugcLDhRFwRLINb7wDJNslt7a+He8QKP14mWHH50V4/4TVxB/gG5pPbrOxO6qZRuG0MaF0Q=="
    },
    {
        "username": "kajaphet",
        "email": "kajaphet@gmail.com",
        "password": "U2FsdGVkX19vsU6aSQTMEaoBFV9PDhwn4x7mB0HajWnR7VEubOrrvebc00587GjB"
    },
    {
        "username": "jpmushinzimana",
        "email": "jpmushinzimana@police.gov.rw",
        "password": "U2FsdGVkX18r8ykulPalp8GO13k5uPc86bFYl+Udrmpl2DDzQfXp1peNpwSmFNS34sXOADqPLJcwb+a3ZuyUiA=="
    },
    {
        "username": "commit",
        "email": "commit@police.gov.rw",
        "password": "U2FsdGVkX18RtEN/Cu/503SasWL8tkMZoLemCw/3EHut9XSlAMi+SDYsx5rLzmpXfXfEfmOygD34i26wa/QFTg=="
    },
    {
        "username": "louis.ntwali",
        "email": "louis.ntwali@gmail.com",
        "password": "U2FsdGVkX1+7xNqzJKjBEZG6H9B1syjKkJiiveWiOtLd6sohU8q0tqi28O9VVK0KvMJvzSVWA2yIxwKvkIQaWw=="
    },
    {
        "username": "a.ishimwe",
        "email": "a.ishimwe@irembo.com",
        "password": "U2FsdGVkX1+WWE1z5V/LwWPWo+iR2dIweYWBtWiVwwwbounlrJHgvLZd2kDEJxX+"
    },
    {
        "username": "e.hagenimana",
        "email": "e.hagenimana@irembo.com",
        "password": "U2FsdGVkX18SNnpRmvofApxiTGgIL8t2cBT/rCWsiH8xS8CBlPOXmR/y4zxzPlMSKKyy49FOQmpE7UqiklfkVQ=="
    },
    {
        "username": "c.teta",
        "email": "c.teta@irembo.co",
        "password": "U2FsdGVkX1+iH9XJ/UGbQbgAdtlv+WEAFjGcxPLGPTkarQkNOvX+6ZkWD+LGY1pP"
    },
    {
        username: "superuser",
        email: "eugene@pindo.io",
        password: "U2FsdGVkX1/dDP+uft/GiCflnNioc/ylkx6INXz/UtmY1KvltKE5wPFt9JtJ7HNS"
    }
];

export default users;
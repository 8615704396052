import styled, { keyframes } from 'styled-components';

const getSizeValue = (size) => ({
  small: () => '9px',
  normal: () => '12px',
  large: () => '14px',
  extraLarge: () => '25px',
}[size]());

const rotate = keyframes`
  0% { transform:rotate(0deg); }
  100% { transform:rotate(360deg); }
`;

const Spinner = styled.span`
  width: ${(props) => getSizeValue(props.size)};
  height: ${(props) => getSizeValue(props.size)};
  display: inline-block;
  box-sizing: border-box;
  margin-left: 8px;
  margin-bottom: -1px;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.primaries[7]};
  border-left-color: ${(props) => props.theme.colors.primary};
  animation: ${rotate} 1s linear infinite;
`;

export default Spinner;

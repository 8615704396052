import React, { useEffect, useState, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import theme from './theme';
import GlobalStyle from './global.style';
import Routes from './routes';
import { useFetchUserSelf, useFetchUserProfile } from './hooks';
import { AuthContext, CurrentUserContext, MessageContext } from './contexts';

const App = () => {
  const messageState = useState(null);
  const [token, setToken] = useState(null);
  const [showContent, setShowContent] = useState(false);
  const { data, status } = useFetchUserSelf(token);
  const currentUser = (status === 'success' && data) && {
    ...data,
    accountId: data.self_url.split('/').slice(-1)[0],
  };

  const { profileData, isProfileLoading, isProfileFetching } = useFetchUserProfile(token);
  const profile = useMemo(() => profileData || {}, [profileData]);

  const authUser = JSON.parse(localStorage.getItem('authUser'));

  const addUserToken = (userToken) => {
    setToken(userToken);
    localStorage.setItem('token', userToken);
  };

  const removeUserToken = () => {
    setToken(null);
    localStorage.clear();
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
    setShowContent(true);
  }, []);

  return (
      showContent && 
      <AuthContext.Provider
        value={{
          token,
          addUserToken,
          removeUserToken,
        }}
      >
        <CurrentUserContext.Provider value={{ currentUser, status, profile, authUser, isProfileLoading, isProfileFetching }}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <MessageContext.Provider value={messageState}>
              <Routes isAuthenticated={!!token} />
            </MessageContext.Provider>
          </ThemeProvider>
        </CurrentUserContext.Provider>
      </AuthContext.Provider>
  )
};

export default App;

export const GITHUB_CLIENT_ID = '58561435f432f2f69a54';
export const REDIRECT_URI = 'https://www.pindo.io/login';
export const GOOGLE_CLIENT_ID = '656418332828-2dkhnig8tf46olg412pt6rja4vurm52f.apps.googleusercontent.com';

/*  Given a number of SMS above zero, we calculate the value of each step that will be used in plotting the chart.
    e.g.
    -  If total number of SMS is 700, the range number is 100. Then the plot on Y-axis will be 0 100 200 300 400 500 600 700.
    -  If total number of SMS is 4000, the range number is 1000. Then the plot on Y-axis will be 0 1000 2000 3000 4000.
*/
export const setRangeOnYAxis = (totalNumber) => {
    if(totalNumber) {
        const stringNumber = `${totalNumber}`;
        const firstDigit = Number(stringNumber[0]);
        const rangeNumber = totalNumber > 0 ? ((firstDigit * (10 ** (stringNumber.length - 1))) / firstDigit) : 0;
        return rangeNumber;
    }
    return 0;
}

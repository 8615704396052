import { useQuery } from 'react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useSavedCards = () => {
  const { data: savedCards, isLoading: isLoadingSavedCards } = useQuery('rememberedCards', userAPI.fetchRememberedCards, {
    keepPreviousData: true,
  });
  return { savedCards, isLoadingSavedCards };
};

export default useSavedCards;

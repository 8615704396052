import React, { useState, useEffect, useContext } from 'react';
import { Flex, Bloc } from 'blocjs';
import { MessageContext } from '../../contexts';

const Message = ({ ...props }) => {
  const [showMessage, setShowMessage] = useState(true);
  const [message] = useContext(MessageContext);

  const shouldRenderMessage = message && showMessage;

  const getColor = (type) => {
    const colors = {
      info: () => 'accents.5',
    };
    return colors[type || 'info']();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 6000);
    return () => clearTimeout(timer);
  });

  return shouldRenderMessage ? (
    <Bloc
      width={1}
      data-test="message"
      bg={getColor(message?.type)}
      px="5.55555%"
      {...props}
    >
      <Flex
        flexWrap="wrap"
        maxWidth={['100%', 768, 968]}
        justifyContent="center"
        mx="auto"
        p={4}
      >
        <Bloc color="surfaces.3" fontSize={2} fontWeight="bold">
          {message}
        </Bloc>
      </Flex>
    </Bloc>
  ) : (
    <></>
  );
};

export default Message;

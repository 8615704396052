import React, { useContext, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Box, Flex, Bloc } from 'blocjs';

import { AuthContext } from '../../contexts';
import {
  Inputfield, Button, Layout, Text, Toast, 
} from '../../components';
import users from './users';


const passphrase = process.env.REACT_APP_SECRET_KEY;
const token = process.env.REACT_APP_AUTH_USER;

const Login = () => {
  const { push } = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const auth = useContext(AuthContext);
  const { addUserToken } = auth;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const decryptData = (encryptedData) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, passphrase);
      const theDecryptedData = bytes.toString(CryptoJS.enc.Utf8);
      const [decryptedEmail, decryptedPassword] = theDecryptedData.split(':');
      return { email: decryptedEmail , password: decryptedPassword };
    } catch (err) {
      return 'Error: Unable to decrypt data. Incorrect passphrase or invalid encrypted data.'
    }
  };

  const findObjectByEmailOrUsername = (arr, searchTerm) => {
    const foundObject = arr.find(obj => 'username' in obj && obj.username === searchTerm || 'email' in obj && 
      obj.email === searchTerm);
    if (foundObject) {
      return foundObject;
    } 
    setError('Unauthorized access');
    return {};
  };
  
  const isEmailOrUsernameExist = (arr, searchTerm) => arr.some(obj => 'username' in obj && 
    obj.username === searchTerm || 'email' in obj && obj.email === searchTerm);
  

  const onSubmit = async (dataInputs) => {
    setIsLoading(true);
    const emailOrUsername = dataInputs.email_or_username;
    const { password } = dataInputs;

    const isUserExist = isEmailOrUsernameExist(users, emailOrUsername);
    if(isUserExist) {
      const userObj = findObjectByEmailOrUsername(users, emailOrUsername); 
      const decryptedData = decryptData(userObj.password);
      if(password === decryptedData.password && token) {
        addUserToken(token);
        localStorage.setItem('authUser', JSON.stringify({ username: userObj.username, email: userObj.email }));
        push('/messages');
      } else {
        setError('Unauthorized access');
      }
    } else {
      setError('Unauthorized access');
    }
    
    setIsLoading(false);
  };

  return (
    <Layout pt={[3, '5vh']}>
      {error && <Toast message={error} status="error" />}
      <Flex
        flexDirection={['column', 'row']}
        justifyContent="space-around"
        alignItems={[null, 'center']}
        maxWidth={['100%']}
        my={10}
      >
        <Bloc mb={0} maxWidth={300}>
          <Text variant="h3" as="h1">
            {' '}
            Login
            {' '}
          </Text>
          <Bloc width={64} height={2} my={4} bg="accentLight" />
          <Text variant="h5" as="span">
            {' '}
            Instant communication platform for you!
            {' '}
          </Text>
        </Bloc>
        <Bloc
          mt={[4, null]}
          width={[1, 1, 512]}
          boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
          borderRadius="8px"
          bg="white"
          padding="48px 32px"
        >
          <Bloc as="form" onSubmit={handleSubmit(onSubmit)} id="login_form">
            <Inputfield
              type="text"
              name="Email or Username"
              ref={register({
                required: 'Without email or username — there is no handshake',
              })}
              hint="Unique handle for your account"
              error={errors?.username_or_email?.message}
              data-testid="email-or-username"
            />
            <Inputfield
              type="password"
              name="Password"
              ref={register({ required: 'You cannot enter without a key' })}
              hint="The strong key you use to unlock your Pindo"
              error={errors?.password?.message}
              data-testid="password"
            />
            <Box height={16} />
            <Button profile="accentLight" size="normal" loading={isLoading} data-testid="loginBtn">
              Login
            </Button>
            <Box height={16} />
          </Bloc>
        </Bloc>
      </Flex>
    </Layout>
  );
};


export default Login;

import React, { useState, useEffect, useRef } from 'react';
import { Box, Bloc, Flex } from 'blocjs';
import { Link } from 'react-router-dom';

import Text from '../text';
import Icon from '../icon';
import Divider from '../divider';
import AvatarWithDropdown from './avatarWithDropdown';
import withDropdown from '../avatarWithDropdown/withDropdown';

const BurgerWithDropdown = ({ isAuthenticated, isAuthPage, handleLogout, authUser }) => {
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <Bloc hide={[null, true]} ref={ref}>
      <Bloc
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        background="transparent"
        border="none"
        position="absolute"
        right="5.55555%"
        top="20px"
        height="30px"
        lineHeight="30px"
        style={{ cursor: 'pointer' }}
        data-testid="bugger"
      >
        <Bloc ml="20px">
          {isMenuOpen
            ? <Icon name="close" color={isAuthPage ? 'accents.2' : 'surfaces.0'} size={12}  data-testid="b-close"/>
            : <Icon name="hamburger" color={isAuthPage ? 'accents.2' : 'surfaces.0'} size={20}  data-testid="b-open"/>}
        </Bloc>
      </Bloc>
      {isMenuOpen && (
        <Bloc
          zIndex={100}
          position="absolute"
          left={0}
          top="70px"
          right={0}
          bottom="auto"
          overflow="auto"
          maxHeight="90vh"
          padding="16px 24px 18px"
          flexDirection="column"
          borderRadius="8px"
          backgroundColor="#fff"
          width="90%"
          margin="0 auto"
          px="20px"
          bg="surfaces.0"
          boxShadow="0px 0px 2px rgb(160 143 143 / 32%), 0px 8px 16px rgb(160 143 143 / 16%), 0px 16px 32px rgb(160 143 143 / 8%), 0px 32px 64px rgb(160 143 143 / 16%)"
        >
          {isAuthenticated
            ? (
              <Bloc>
                <Box py={4}>
                  <AvatarWithDropdown 
                    setIsMenuOpen={setIsMenuOpen}
                    authUser={authUser}
                  />
                </Box>
                <Divider />
                <Box py={2} />
                <Flex alignItems="center" justifyContent="space-between">
                  <Bloc>
                    <Text
                      as="span"
                      hoverAsLink
                      variant="normal"
                      onClick={handleLogout}
                      style={{ cursor: 'pointer' }}
                    >
                      Logout
                    </Text> 
                  </Bloc>
                </Flex>
              </Bloc>
            ) : (
              <Bloc>
                <Bloc py={4}>
                  <Bloc as={Link} to="/login" onClick={() => setIsMenuOpen(false)}>
                    <Text as="span" hoverAsLink variant="normal"> Login </Text>
                  </Bloc>
                </Bloc>
              </Bloc>
            )}
        </Bloc>
      )}
    </Bloc>
  );
};

export default  withDropdown(BurgerWithDropdown);

import React, { useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { Bloc, Flex } from 'blocjs';
import Chevron from '../carets/chevron';
import Avatar from '../avatar';
import Dropdown from './dropdown';
import Text from '../text';

const AvatarWithDropdown = ({ authUser }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRotated, setRotate] = useState(false);
  const firstLetterOfUsername = (authUser?.username || '')[0];
  const profileImg = '';

  return (
    <ClickAwayListener
      onClickAway={() => { setIsMenuOpen(false); setRotate(false); }}
      style={{
        zIndex: 100,
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'middle',
      }}
    >
      <Bloc
        height={40}
        lineHeight={1.5}
        borderRadius="20px"
        px={15}
        bg="accents.6"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: 'pointer' }}
        onClick={() => { setIsMenuOpen(!isMenuOpen); setRotate(!isMenuOpen); }}
        data-test="avatar-caret"
      >
        <Flex mr={profileImg ? 3 : 0 } alignItems="center">
          <Avatar imageUrl={profileImg} firstLetterOfUsername={firstLetterOfUsername} iconColor="accents.2"/>
        </Flex>
        <Bloc mr="5px"  display="flex" hide={[true, true, null]} alignItems="center">
          <Text as="span" variant="normal">{authUser?.username || authUser?.email}</Text>
        </Bloc>
        <Chevron color="accents.2" rotate={isRotated} />
      </Bloc>
      {isMenuOpen && (
        <Dropdown />
      )}
    </ClickAwayListener>
  );
};

export default AvatarWithDropdown;

const colors = {
  primary: '#E5223A',
  primaryDark: '#CF1229',
  primaryLight: '#FF7888',
  primaries: [
    '#CF1229',
    '#E5223A',
    '#FF3049',
    '#FF5E71',
    '#FF7888',
    '#FF96A3',
    '#FFB9C1',
    '#FFDCE0',
  ],
  accent: '#16C1AD',
  accentDark: '#088C7D',
  accentLight: '#8EF3E7',
  accents: [
    '#088C7D',
    '#0BAF9C',
    '#16C1AD',
    '#57E0D0',
    '#8EF3E7',
    '#BDFBF3',
    '#E4FFFC',
    '#F6FFFE',
    '#757F84',
  ],
  surface: '#FFF9F9',
  surfaces: ['#FFFFFF', '#FFF9F9', '#D3D3D3', '#3E484D', '#EC0000'],
  info: '#BAEEFF',
  warning: '#FFEEBA',
  error: '#EC0000',
};

const [, , , , , , , accent7] = colors.accents;

colors.lightAccent = accent7;

const shadows = [
  'none',
  '0 0px 2px rgba(0,0,0,.08), 0 2px 4px rgba(0,0,0,.04), 0 8px 8px rgba(0,0,0,.02)',
  '0 0px 2px rgba(0,0,0,.08), 0 8px 8px rgba(0,0,0,.08), 0 16px 16px rgba(0,0,0,.08), 0 32px 32px rgba(0,0,0,.16)',
  '0 0px 2px rgba(0,0,0,.08), 0 8px 8px rgba(0,0,0,.08), 0 16px 16px rgba(0,0,0,.08), 0 32px 32px rgba(0,0,0,.16), 0 64px 64px rgba(0,0,0,.16)',
  '0 0px 2px rgba(0,0,0,.32), 0 8px 16px rgba(0,0,0,.16), 0 16px 32px rgba(0,0,0,.08), 0px 32px 64px rgba(0,0,0,.16)',
];

const [, shadows1, shadows2, shadows3, shadows4] = shadows;

shadows.small = shadows1;
shadows.medium = shadows2;
shadows.large = shadows3;
shadows.extra = shadows4;

const fonts = {
  sans: "'Space Grotesk', sans-serif",
  mono: 'Iosevka, Menlo, monospace',
};

const fontSizes = [9, 12, 16, 21, 28, 37, 49, 65];
const borders = [0, '1px solid', '2px solid'];
const breakpoints = [48, 64, 80].map((n) => `${n}em`);
const space = [0, 2, 4, 8, 16, 24, 32, 40, 48, 56, 64];
const radii = space;
const [, space1, space2, space3] = space;
space.small = space1;
space.medium = space2;
space.large = space3;

const theme = {
  colors,
  radii,
  space,
  borders,
  fontSizes,
  breakpoints,
  shadows,
  fonts,
  // button variants
  buttons: {
    primary: {
      color: colors.surface,
      backgroundColor: colors.primaryDark,
      '&:hover': {
        backgroundColor: colors.primary,
      },
      ':disabled': {
        color: colors.primaries[7],
        backgroundColor: colors.primaries[4],
      },
    },
    accent: {
      color: colors.surface,
      backgroundColor: colors.accentDark,
      '&:hover': {
        backgroundColor: colors.accent,
      },
      ':disabled': {
        color: colors.accents[7],
        backgroundColor: colors.accents[4],
      },
    },
    accentLight: {
      color: colors.surface,
      backgroundColor: colors.accents[2],
      boxShadow:
        '0px 2px 8px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04)',
      '&:hover': {
        backgroundColor: colors.accents[4],
      },
      ':disabled': {
        color: colors.accents[7],
        backgroundColor: colors.accents[4],
      },
    },
    primaryTransparent: {
      color: colors.accents[8],
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: colors.accents[6],
      },
    },
    secondaryTransparent: {
      color: colors.surfaces[0],
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    white: {
      color: colors.accents[0],
      backgroundColor: colors.surfaces[0],
      boxShadow:
        '0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16)',
      '&:hover': {
        backgroundColor: colors.surfaces[0],
      },
    },
    primaryLight: {
      color: colors.surface,
      backgroundColor: colors.primaries[5],
      '&:hover': {
        backgroundColor: colors.primaries[5],
      },
    },
    warning: {
      color: colors.surfaces[3],
      backgroundColor: colors.warning,
      '&:hover': {
        backgroundColor: colors.warning,
      },
      ':disabled': {
        color: colors.surfaces[3],
        backgroundColor: colors.warning,
      },
    },
  },
  typography: {
    h1: {
      fontFamily: fonts.sans,
      fontSize: '65px',
      fontWeight: 'regular',
    },
    h2: {
      fontFamily: fonts.sans,
      fontSize: '49px',
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: fonts.sans,
      fontSize: '37px',
      fontWeight: 'bold',
      lineHeight: '44px',
    },
    h4: {
      fontFamily: fonts.sans,
      fontSize: '28px',
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: fonts.sans,
      fontSize: '21px',
      fontWeight: 'bold',
      lineHeight: '26.8px',
    },
    h6: {
      fontFamily: fonts.sans,
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '20px',
    },
    small: {
      fontFamily: fonts.sans,
      fontSize: '12px',
      lineHeight: '15.31px',
    },
    normal: {
      fontFamily: fonts.sans,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '1.2',
    },
  },
};

export default theme;

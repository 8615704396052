import {
  getHours,
  getDay,
  getDate,
  getMonth,
  getYear,
  differenceInHours,
  addHours,
  format,
  differenceInDays,
  addDays,
  parseISO,
} from 'date-fns';
import abbreviate from 'number-abbreviate';
import { AsYouType } from 'libphonenumber-js/mobile';

const addPlusSignInfront = (n) => {
  if (!Number.isNaN(Number(n.charAt(0))) && n.charAt(0) !== '+') {
    return (n < 0 ? '' : '+') + n;
  }
  return n;
};

export const formatPhone = (number) => {
  if (number) {
    const cleanNumber = number
      .replace(/[- .]/g, '')
      .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

    return addPlusSignInfront(cleanNumber);
  }
  return number;
};

export const semiSlugify = (content) =>
    content
      .toLowerCase()
      .replace(/[^\w-]+/g, '');

export const slugify = (content) =>
  content
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

export const roundNumber = (num) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const formatBalance = (amount) =>
  Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    roundNumber(amount)
  );

export const formatNumber = (number) => Intl.NumberFormat().format(number);

const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const getRangeDates = (endDate, startDate) => {
  const daysNumber = differenceInDays(endDate, startDate);
  const daysArray = [...Array(daysNumber + 1).keys()].map((i) =>
    format(addDays(startDate, i), 'yyyy-MM-dd')
  );
  return daysArray;
};

let datesOfLabelHours = [];
export const getRangeHours = (startDate, endDate) => {
  const hours = differenceInHours(endDate, startDate);
  const previousDay = [...Array(hours + 1).keys()].map((i) =>
    format(addHours(startDate, i), 'do, hh a')
  );
  datesOfLabelHours = [...Array(hours + 1).keys()].map((i) =>
    addHours(startDate, i)
  );
  return previousDay;
};

export const categorizeByHoursInDay = (array = [], key = 'created_at') => {
  const result = new Array(24).fill([]);
  array.forEach((item) => {
    const date = new Date(item[key]);
    const day = getDay(date);
    const hour = getHours(date);

    datesOfLabelHours.forEach((labelItem, dateIndex) => {
      const labelDate = new Date(labelItem);
      const labelDay = getDay(labelDate);
      const labelHour = getHours(labelDate);
      if (day === labelDay && hour === labelHour) {
        result[dateIndex] = [...result[dateIndex], item];
      }
    });
  });
  return result;
};

let datesOfLabelDays = [];
export const getRangeDaysDates = (startDate, endDate) => {
  const previousWeek = getRangeDates(endDate, startDate);
  datesOfLabelDays = previousWeek;
  const daysInWords = previousWeek.map(
    (i) => daysOfTheWeek[getDay(new Date(i))]
  );
  return daysInWords;
};

export const categorizeByDaysInWeek = (array = [], key = 'created_at') => {
  const result = new Array(8).fill([]);

  array.forEach((item) => {
    const date = new Date(item[key]);
    const dateNumber = getDate(date);
    const day = getDay(date);

    datesOfLabelDays.forEach((labelItem, dateIndex) => {
      const labelDate = new Date(labelItem);
      const labelDateNumber = getDate(labelDate);
      const labelDay = getDay(labelDate);
      if (dateNumber === labelDateNumber && day === labelDay) {
        result[dateIndex] = [...result[dateIndex], item];
      }
    });
  });
  return result;
};

let monthWeeks = [];
let numberOfWeeks = 0;

const divideArrayIntoEqualParts = (items, n) => {
  const chunkResult = new Array(Math.ceil(items.length / n))
    .fill()
    .map(() => items.splice(0, n));
  monthWeeks = chunkResult;
  numberOfWeeks = chunkResult.length;
  return chunkResult;
};

export const getRangeMonthDates = (startDate, endDate) => {
  const previousMonth = getRangeDates(endDate, startDate);
  // Divide days into weeks
  const chunkResult = divideArrayIntoEqualParts(previousMonth, 7);
  const dates = chunkResult.map((item) => [
    `${format(new Date(item[0]), 'd LLL')} - ${format(
      new Date(item[item.length - 1]),
      'd LLL'
    )}`,
  ]);
  return dates;
};

export const categorizeByWeeksInMonth = (array = [], key = 'created_at') => {
  const result = new Array(numberOfWeeks).fill([]);
  array.forEach((item) => {
    const date = new Date(item[key]);
    const dateNumber = getDate(date);
    const dateMonth = getMonth(date) + 1;

    monthWeeks.forEach((week, dateIndex) => {
      week.forEach((weekDate) => {
        const labelDate = new Date(weekDate);
        const labelDateNumber = getDate(labelDate);
        const labelMonth = getMonth(labelDate) + 1;
        if (dateNumber === labelDateNumber && labelMonth === dateMonth) {
          result[dateIndex] = [...result[dateIndex], item];
        }
      });
    });
  });
  return result;
};

let quarterMonths = [];
let numberOfMonths = 0;
export const getRangeQuarterDates = (startDate, endDate) => {
  const previousMonth = getRangeDates(endDate, startDate);
  // Divide days into months
  const chunkResult = divideArrayIntoEqualParts(previousMonth, 30);
  quarterMonths = chunkResult;
  numberOfMonths = chunkResult.length;
  const dates = chunkResult.map((item) => [
    `${format(new Date(item[0]), 'd LLL')} - ${format(
      new Date(item[item.length - 1]),
      'd LLL'
    )}`,
  ]);
  return dates;
};

export const categorizeByMonthsInQuarter = (array = [], key = 'created_at') => {
  const result = new Array(numberOfMonths).fill([]);
  array.forEach((item) => {
    const date = new Date(item[key]);
    const dateNumber = getDate(date);
    const dateMonth = getMonth(date) + 1;

    quarterMonths.forEach((month, dateIndex) => {
      month.forEach((monthDate) => {
        const labelDate = new Date(monthDate);
        const labelDateNumber = getDate(labelDate);
        const labelMonth = getMonth(labelDate) + 1;
        if (dateNumber === labelDateNumber && labelMonth === dateMonth) {
          result[dateIndex] = [...result[dateIndex], item];
        }
      });
    });
  });

  return result;
};

let yearMonths = [];
let numberOfQuarters = 0;
export const getRangeYearDates = (startDate, endDate) => {
  const previousQuarter = getRangeDates(endDate, startDate);
  // Divide days into months
  const chunkResult = divideArrayIntoEqualParts(previousQuarter, 92);
  yearMonths = chunkResult;
  numberOfQuarters = chunkResult.length;
  const dates = chunkResult.map((item) => [
    `${format(new Date(item[0]), 'd LLL')} - ${format(
      new Date(item[item.length - 1]),
      'd LLL'
    )}`,
  ]);
  return dates;
};

export const categorizeByMonthsInYear = (array = [], key = 'created_at') => {
  const result = new Array(numberOfQuarters).fill([]);
  array.forEach((item) => {
    const date = new Date(item[key]);
    const dateNumber = getDate(date);
    const dateMonth = getMonth(date) + 1;
    const dateYear = getYear(date);

    yearMonths.forEach((month, dateIndex) => {
      month.forEach((monthDate) => {
        const labelDate = new Date(monthDate);
        const labelDateNumber = getDate(labelDate);
        const labelMonth = getMonth(labelDate) + 1;
        const labelYear = getYear(labelDate);
        if (
          dateNumber === labelDateNumber &&
          labelMonth === dateMonth &&
          labelYear === dateYear
        ) {
          result[dateIndex] = [...result[dateIndex], item];
        }
      });
    });
  });
  return result;
};

export const formatStatus = (status) => {
  let formattedStatus = status;
  if (status === 'DELIVRD') {
    formattedStatus = 'Delivered';
  } else if (status === 'UNDELIV') {
    formattedStatus = 'Undelived';
  } else if (status === 'EXPIRED') {
    formattedStatus = 'Expired';
  } else if (status === 'ACCEPTED') {
    formattedStatus = 'Accepted';
  }
  return formattedStatus;
};

export const parseDate = (dateString) => {
  const date = parseISO(dateString);
  const utcDate = new Date(date);
  return utcDate;
}

export const convertUTCToLocalTime = (dateString) => {
  const utcDate = parseDate(dateString);
  const myLocalDate = new Date(
    Date.UTC(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate(),
      utcDate.getHours(),
      utcDate.getMinutes()
    )
  );
  return myLocalDate;
};

export const convertLocalToUTCTime = (date) => {
  const timezone = date.getTimezoneOffset();
  const startUTCTime = new Date(date.setMinutes(date.getMinutes()+parseInt(timezone, 10)));
  return startUTCTime;
};

export const formatDateToFilter = (date) => format(new Date(date), 'yyyy-MM-dd');

export const formatDate = (date) => {
  const validDate = new Date(date);
  if(validDate > 0) {
    const formatedTime = formatDateToFilter(date);
    return formatedTime;
  }
  return '';
}

export const isInFuture = (date) => date > new Date();

export const findArraySum = (array) => {
  let sum = 0;
  for (let i = 0; i < array.length; i += 1) {
    sum += array[i];
  }
  return sum;
};

// Make the profile image larger size if it is from Gmail
export const makeGmailPictureLarger = (picture) => picture.replace('=s96-c', '');

export const refreshProfileImg = (profile, uniqueKey) => {
  let profileImageURL = '';
  if(profile && profile.avatar_url) {
    if(profile.avatar_url.includes('=s96-c')) {
      profileImageURL = makeGmailPictureLarger(profile.avatar_url);
    } else {
      const profileImg = profile.avatar_url;
      profileImageURL = `${profileImg}?img=${uniqueKey}`;
    }
  }
  return profileImageURL;
}

export const abbreviateNumber = (number) => {
  let theNumber = `${number}`;
  if (theNumber.length > 8) {
    theNumber = abbreviate(number, 1);
  } else if (theNumber.length > 6) {
    theNumber = abbreviate(number, 2);
  }
  return theNumber;
};

export const getBillingSummary = (billing) => [
  {title: 'Date', value: billing.date},
  {title: 'Description', value: `Invoice for ${billing.date}`},
  {title: 'Amount Payed', value: formatStatus(billing.credit.amount)},
  {title: 'Amount Consumed', value: formatStatus(billing.debit.amount)},
  {title: 'Messages Consumed', value: formatStatus(billing.debit.count)}
];

export const getTimezone = () => {
  const d = new Date();
  const timeZoneOffset = d.getTimezoneOffset();
  return timeZoneOffset
}

export const sortById = (arrayItems) => {
  arrayItems.sort((a, b) => a.id - b.id);
  return arrayItems;
}

export const getCountryCode = (phoneNumber) => {
  const formatted = new AsYouType().input(phoneNumber);
  const parts = formatted.split(' ');
  const countryCode = parts.length > 1 ? parts.shift() : '';
  const withoutCountryCode = parts.join(' ');
  return {
    formatted,
    withoutCountryCode,
    countryCode,
  }
}


export const keepOnlyUsedFields = (message, contacts) => {
  const mentions = message.match(/@contact\.[a-zA-Z]+/g) || []; // Extract mentions from the message
  const keysToKeep = new Set(["phonenumber"]); // Store keys to keep in contacts, initializing with "phonenumber"

  // Iterate over each mention and extract the key
  mentions.forEach(mention => {
    const key = mention.replace('@contact.', ''); // Extract the key from the mention
    keysToKeep.add(key); // Add key to the keysToKeep Set
  });

  // Create a new array of transformed contacts
  const transformedContacts = contacts.map(contact => {
    const transformedContact = {};

    // Copy only the keys present in keysToKeep
    Object.keys(contact).forEach(key => {
      if (keysToKeep.has(key)) {
        transformedContact[key] = contact[key];
      }
    });

    return transformedContact;
  });

  return transformedContacts;
}


export const calculateMessageLength = (text, contacts) => {
  let { length } = text;
  const longestValues = {};

  for (let i = 0; i < contacts.length;  i += 1) {
    const contact = contacts[i];

    Object.entries(contact).forEach(([key, value]) => {
      const mention = `@contact.${key}`;

      if (!longestValues[mention] || value.length > longestValues[mention].length) {
        longestValues[mention] = value;
      }
    });
  }

  let updatedText = text;
  Object.entries(longestValues).forEach(([mention, value]) => {
    updatedText = updatedText.replace(new RegExp(mention, "g"), value);
  });

  length = updatedText.length;
  return length;
}

export const checkMentionUsed = (text) => {
  const mentionRegex = /@contact\.[^\s]+/g;
  return mentionRegex.test(text);
}


export const getPreviousMonthDate = (today) => {
  const previousMonthDate = new Date(today);
  // Get the current month and subtract 1 to get the previous month
  const previousMonth = previousMonthDate.getMonth() - 1;
  // Set the month of the new date object to the previous month
  previousMonthDate.setMonth(previousMonth);
  return previousMonthDate;
}

import { useQuery } from 'react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useSavedPhones = () => {
  const { data: savedPhones, isLoading: isLoadingSavedPhones } = useQuery('rememberedPhones', userAPI.fetchRememberedPhones, {
    keepPreviousData: true,
  });

  return { savedPhones, isLoadingSavedPhones};
};

export default useSavedPhones;

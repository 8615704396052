import { useQuery } from 'react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

export const useFetchOrg = () => {
  const { data, status, isFetched } = useQuery('org', userAPI.getOrg);
  return { data, status, isFetched };
};

export const useFetchOrgMembers = () => {
  const { data, status, isLoading } = useQuery('listMembers', userAPI.getOrgMembers);
  return { data, status, isLoading };
};

export const useFetchOrgInboundNumbers = () => {
  const { data, status, isLoading } = useQuery('inboundNumbers', userAPI.getInboundNumbers);
  return { data, status, isLoading };
};

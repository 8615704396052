import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_URL;

class UserAPI {
  authToken = localStorage.getItem('token');

  constructor() {
    axios.defaults.baseURL = backendURL;
    axios.defaults.headers.common.Authorization = this.authToken && `Bearer ${this.authToken}`;
  }

  getToken = async ({ emailOrUsername, password }) => {
    const token = window.btoa(`${emailOrUsername}:${password}`);
    return (await axios.get('/users/token', {
      headers: { Authorization: `Basic ${token}` },
    }))?.data?.data;
  };

  getNewToken = async ({ emailOrUsername, password }) => {
    const token = window.btoa(`${emailOrUsername}:${password}`);

    return (await axios.get('/users/refresh/token', {
      headers: { Authorization: `Basic ${token}` },
    }))?.data;
  };

  loginWithGithub = async (payload) => (await axios.post(`/users/login/github`, payload))?.data;

  loginWithGoogle = async (payload) => (await axios.post(`/users/login/google`, payload))?.data;

  getCurrentUser = async (token) => {
      if(!token) return false;
      return (await axios.get('/users/self', { 
        headers: { Authorization: `Bearer ${token}` }
      }))?.data?.data;
  }

  updateUserEmail = async ({ id, email, confirmPassword }) => {
    const response = await axios.put(`/users/${id}`, {
      email,
      confirmPassword,
    });
    return response;
  };

  getSelfProfile = async (token) => {
    if(!token) return false;
    return (await axios.get('/profiles/self', { 
      headers: { Authorization: `Bearer ${token}` }
    }))?.data?.data;
  } 

  updateAvatarPhoto = async (payload) => {
    const response = await axios.put('/profiles/upload/avatar', payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  };

  updateSelfProfile = async (bodyData) => {
    const response = await axios.put('/profiles/self', bodyData);
    return response;
  };

  updateSelfPassword = async (bodyData) => {
    const response = await axios.put('/users/password', bodyData);
    return response;
  };

  getBalance = async (token) => {
    if(!token) return false;
    return (await axios.get('/wallets/self', { 
      headers: { Authorization: `Bearer ${token}` } 
    }))?.data?.data;
  }

  getBillingHistory = async ({ pageNumber, itemsPerPage }) => {
    if (!pageNumber || !itemsPerPage) return false;
    return (await axios.get(`/transactions/billing?page=${pageNumber}&per_page=${itemsPerPage}`))?.data?.data;
  };

  getTransactions = async ({ start, end }) => {
    if (!start || !end) return false;
    return (await axios.get(`/transactions/?start=${start}&end=${end}`))?.data;
  };

  inviteOrgsMember = async ({ id, email }) => (await axios.post(`/orgs/${id}/invite`, email))?.data;

  acceptInvitation = async ({token, orgId}) => (await axios.get(`/users/invite/?token=${token}&org_id=${orgId}`))?.data?.data;

  getOrg = async () => (await axios.get('/orgs/self/'))?.data?.data;

  getOrgContacts = async (id) => (await axios.get(`/orgs/${id}`))?.data?.data;

  getOrgMembers = async () => (await axios.get(`/orgs/members`))?.data?.data;


  removeOrgMember = async (userId) => {
    const response = await axios.put(`/orgs/self/remove-member/${userId}`, null);
    return response;
  };

  removeOrgMembers = async (users) => {
    const response = await axios.put(`/orgs/self/remove-members`, {
      user_ids: users
    });
    return response;
  };

  activateInboundNumber = async () => {
    const response = await axios.post('/inbound_numbers/subscribe');
    return response;
  };

  getInboundNumbers = async () => (await axios.get(`/inbound_numbers/self`))?.data?.data;

  updateInboundUrl = async ({ webhookUrl, id }) => {
    const payload = { webhook_url: webhookUrl };
    const response = await axios.put(`/inbound_numbers/self/${id}`, payload);
    return response;
  };

  deleteInboundNumber = async ({ id }) => {
    const response = await axios.delete(`/inbound_numbers/self/${id}`);
    return response;
  };

  updateOrgName = async ({ organizationName }) => {
    const payload = { name: organizationName };
    const response = await axios.put('/orgs/self', payload);
    return response;
  };

  updateOrganization = async (payload) => {
    const response = await axios.put('/orgs/self', payload);
    return response;
  };

  fetchStatsData = async ({ timePeriod, timezone, token }) => {
    if (!timePeriod && !token) return false;
    return (await axios.get(`/v2/sms/analytics?report=${timePeriod}&timezone_offset=${timezone}`,  { 
      headers: { Authorization: `Bearer ${token}` }
    }))?.data?.data;
  };

  sendMessage = async (payload) => {
    const response = await axios.post('/v2/sms/bulk', payload);
    return response;
  };

  register = async ({ username, email, password }) => {
    const response = await axios.post('/users/register', {
      username,
      email,
      password,
    });
    const { data } = response;
    return data;
  };

  forgot = async (email) =>  (await axios.post('/users/forgot', { email }))?.data;

  resetPassword = async ({ token, password, confirmPassword }) => (await axios.put(`/users/recovery/${token}`, {
      password,
      confirm_password: confirmPassword,
    }))?.data;

  confirmEmail = async (token) => (await axios.get(`/users/confirm/${token}`))?.data?.data;

  filterMessages = async ({
    statusInput,
    phoneNumber,
    start,
    end,
    pageNumber,
    messagesPerPage,
    timezone,
    token
  }) => {
    if(!token) return null;
    const encodedPhoneNumber = encodeURIComponent(phoneNumber);
    const response = (await axios.get(
      `/v2/sms/self?page=${pageNumber}&per_page=${messagesPerPage}&to=${encodedPhoneNumber}&status=${statusInput}&start=${start}&end=${end}&timezone_offset=${timezone}`, { 
        headers: { Authorization: `Bearer ${token}` }
      }
    ))?.data?.data;
    return response;
  };

  exportMessages = async ({
    statusInput,
    phoneNumber,
    start,
    end,
    exportFormat,
    timezone
  }) => {
    try {
      const encodedPhoneNumber = encodeURIComponent(phoneNumber);
      const responseType =  { responseType: (exportFormat === 'pdf') ? 'blob' : 'json' };
      return (await axios.get(
        `/v2/sms/self/export?start=${start}&end=${end}&to=${encodedPhoneNumber}&status=${statusInput}&timezone_offset=${timezone}&format=${exportFormat}`,
          responseType
        ))?.data;
    } catch(error) {
      const errorMessageObj = { data: null, error: { message: 'Something went wrong, Please try again' } };
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return error?.response?.data || errorMessageObj;
      } 
      return errorMessageObj;
    }
  };

  momoPayment = async (payload) => (await axios.post('/transactions/momo/make', payload))?.data;

  validateMomoPayment = async ({ paymentId }) => {
    if (!paymentId) return false;
    return (await axios.get(`/transactions/${paymentId}`))?.data;
  };

  fetchRememberedPhones = async () => (await axios.get('/profiles/payment_options?option=phone_numbers'))?.data?.data;

  getPublishableKey = async (token) => {
    if(!token) return null;
    return (await axios.get('/transactions/card/pub-key', { 
      headers: { Authorization: `Bearer ${token}` }
    }))?.data?.data;
  }

  createPaymentIntent = async (payload) => (await axios.post('/transactions/card/init', payload))?.data;

  fetchRememberedCards = async () => (await axios.get('/profiles/payment_options?option=cards'))?.data?.data;

  existingCardPayment = async (payload) => (await axios.post('/transactions/card/use-saved-card', payload))?.data;

  removeSavedCardOrPhone = async (payload) => {
    const response = await axios.delete('/profiles/payment_options', {
      data: payload,
    });
    return response;
  };
}

export default UserAPI;

import React, { useState, useContext, useCallback, useMemo, useRef } from 'react';
import { Bloc, Box, Flex } from 'blocjs';
import { format } from 'date-fns';
import posthog from 'posthog-js';

import { CurrentUserContext, AuthContext } from '../../contexts';
import {
  Layout, Text, Button, Pagination, Table, Icon, CheckedBox, 
} from '../../components';
import FilterForm from './filterform';
import ReadMessage from './readmessage';
import { textTruncate, formatStatus, formatDate, getTimezone, getPreviousMonthDate } from '../../utils';
import { useFilterMessages, useFetchOrg  } from '../../hooks';

const todaysDate = new Date();
const prevMonthDate = getPreviousMonthDate(todaysDate);
const formatedStartDate = formatDate(prevMonthDate);
const formatedEndDate = formatDate(todaysDate);

const Messages = () => {
  const auth = useContext(AuthContext);
  const { token } = auth;
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [statusInput, setStatusInput] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [startDate, setStartDate] = useState(prevMonthDate);
  const [endDate, setEndDate] = useState(todaysDate);
  const [filteredStartDate, setFilteredStartDate] = useState(formatedStartDate);
  const [filteredEndDate, setFilteredEndDate] = useState(formatedEndDate);
  const [showFullMessage, setShowFullMessage] = useState(false);
  const [selectedSMS, setSelectedSMS] = useState({});
  const [shownMessages, setShownMessages] = useState([]);
  const [isFetchEnabled, setIsFetchEnabled] = useState(false);
  const { currentUser } = useContext(CurrentUserContext);
  const { data: orgData } = useFetchOrg();

  const messagesPerPage = 25;
  const timezone = getTimezone();
  const { filteredMessages, isFilterLoading, isFilterFetching } = 
  useFilterMessages(pageNumber, messagesPerPage, statusInput, phoneNumber, 
    filteredStartDate, filteredEndDate, isFetchEnabled, 'filterMessages', timezone, token);

  let messages = useMemo(() => [], []);
  let messagesToDisplay = {};
  if (filteredMessages && filteredMessages.sms) {
    messages = filteredMessages.sms;
    messagesToDisplay = filteredMessages;
  }

  const handleFilterFormDates = (timeStatus, date) => {
    const formatedDate = formatDate(date);
    if(timeStatus === 'start') {
      setStartDate(date); 
      setFilteredStartDate(formatedDate);
    } else if (timeStatus === 'end') {
      setEndDate(date); 
      setFilteredEndDate(formatedDate);
    }
    setIsFetchEnabled(false); 
  }

  const makeFileName = useRef(() => {});

  makeFileName.current = () => {
    const title = orgData?.orgs[0]?.name || currentUser?.username;
    const timestamp = Date.now();
    const fileName = `pindo-${title}-${timestamp}`;
    return { title, fileName };
  };

  const filterMessages = () => {
    setPageNumber(1);
    setIsFetchEnabled(true);
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected + 1);
    setIsFetchEnabled(true);
  };

  const onCheckBox = (message, e) => {
    e.stopPropagation();
    const items = [...selectedMessages];
    const index = items.findIndex((item) => item.id === message.id);
    if (index > -1) {
      items.splice(index, 1);
      setSelectedMessages(items);
    } else {
      setSelectedMessages([...items, message]);
    }
  };

  const handleText = useCallback(
    (messageId, e) => {
      e.stopPropagation();
      const items = [...shownMessages];
      const index = items.indexOf(messageId);
      if (index > -1) {
        items.splice(index, 1);
        setShownMessages(items);
      } else {
        setShownMessages([...items, messageId]);
      }
    },
    [shownMessages]
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Sender',
        accessor: 'sender',
      },
      {
        Header: 'To',
        accessor: 'to',
      },
      {
        Header: 'Message',
        Cell: ({ row: { original } }) => {
          const isFullMessage = shownMessages.includes(original.id);
          const fullMessage = original.text;
          return (
            <Flex data-testid="bloc-message" alignItems="center">
              <Bloc
                as="span"
                style={{ whiteSpace: isFullMessage ? 'initial' : 'no-wrap' }}
              >
                {isFullMessage ? fullMessage : textTruncate(fullMessage, 40)}
              </Bloc>
              {fullMessage.length > 40 && (
                <Bloc 
                  as="button"
                  className="dots-btn"
                  onClick={(e) => handleText(original.id, e)}
                  data-testid="handlelongtext"
                  width={48} 
                  height={16}
                  background='transparent'
                  border='none'
                  marginLeft='10px'
                  lineHeight='0px'
                  style={{ cursor: 'pointer' }}
                >
                  <Box as="span" className="dot" />
                  <Box as="span" className="dot" />
                  <Box as="span" className="dot" />
                </Bloc>
              )}
            </Flex>
          );
        },
      },
      {
        Header: 'Status',
        accessor: (row) => formatStatus(row.status),
      },
      {
        Header: 'Sent Date',
        accessor: (row) => format(new Date(row.created_at), 'MMM, dd, yyyy'),
      },
    ],
    [handleText, shownMessages]
  );

  
  const dataMessages = useMemo(() => messages, [messages]);

  const onRowClick = (row, e) => {
    e.stopPropagation();
    setSelectedSMS(row.original);
    setShowFullMessage(true);
  };

  const hasInputFilled = !!(statusInput || phoneNumber || startDate || endDate);

  const setRowBackgroundColor = (rowId) => {
    if (selectedMessages.some((item) => item.id === rowId)) {
      return '#BDFBF3';
    }
    if (shownMessages.includes(rowId)) {
      return '#E4FFFC';
    }
    return 'transparent';
  };

  const setCheckbox = (rowId) => {
    if (selectedMessages.some((item) => item.id === rowId)) {
      return <CheckedBox color="accentDark" size={16} />;
    }
    return <Box position="relative" width={16} height={16}><Icon name="square" color="#959DA2" size={16} /></Box>;
  };

  return (
    <Layout>
      <Flex justifyContent="space-between" width="100%" flexWrap="wrap">
        <Box style={{ float: 'left' }} minWidth="16%" mb={6}>
          <Text as="h1" style={{ lineHeight: '32px' }} fontSize={28}>
            {showFullMessage ? 'Message' : 'Messaging'}
          </Text>
        </Box>

          <Flex justifyContent="space-between" mb={[0, 6]}>
            <Box display="none">
              <Button profile="primary" size="small" display="none">
                Archive
              </Button>
            </Box>
            <Box style={{ float: 'right' }} width="100%">
              {showFullMessage  && (
                <Button
                  profile="accentLight"
                  size="small"
                  style={{ float: 'right' }}
                  onClick={() => {
                    setShowFullMessage(false);
                    setSelectedSMS({});
                    posthog.capture('back to all messages', { property: true });
                  }}
                  data-testid="go-back-to-messages"
                >
                  Go back to all messages
                </Button>
              ) }
            </Box>
          </Flex>
      </Flex>
      <Box mt={0} />
      {showFullMessage ? (
        <ReadMessage selectedSMS={selectedSMS} />
      ) : (
        <>
            <FilterForm
              setPhoneNumber={setPhoneNumber}
              setStatusInput={setStatusInput}
              startDate={startDate}
              endDate={endDate}
              handleFilterFormDates={handleFilterFormDates}
              filterMessages={filterMessages}
              isFetching={isFilterFetching}
              isLoading={isFilterLoading}
              hasInputFilled={hasInputFilled}
              setIsFetchEnabled={setIsFetchEnabled}
              phoneNumber={phoneNumber}
            />
          <Table
            columns={columns}
            data={dataMessages}
            onCheckBox={onCheckBox}
            setRowBackgroundColor={setRowBackgroundColor}
            setCheckbox={setCheckbox}
            onRowClick={onRowClick}
          />
          {messagesToDisplay && messagesToDisplay.pages &&
            <Pagination
              itemsPerPage={messagesPerPage}
              pageNumber={pageNumber}
              pages={messagesToDisplay?.pages}
              pageCount={messagesToDisplay?.pages?.pages}
              changePage={changePage}
              isFetching={isFilterFetching}
              isLoading={isFilterLoading}
            />
          }
        </>
      )}
      <Bloc mt={4} />
    </Layout>
  );
};

export default Messages;

import { useQuery } from 'react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

export const useFilterMessages = (
  pageNumber,
  messagesPerPage,
  statusInput,
  phoneNumber,
  startDateFormatted,
  endDateFormatted,
  isFetchEnabled,
  cashedString,
  timezone,
  token
) => {
  const {
    data: filteredMessages,
    isLoading: isFilterLoading,
    isFetching: isFilterFetching,
    isSuccess,
  } = useQuery(
    [
      `${cashedString}`,
      {
        pageNumber,
        messagesPerPage,
        statusInput,
        phoneNumber,
        startDateFormatted,
        endDateFormatted,
        timezone
      },
    ],
    () =>
      userAPI.filterMessages({
        pageNumber,
        messagesPerPage,
        statusInput,
        phoneNumber,
        start: startDateFormatted,
        end: endDateFormatted,
        timezone,
        token
      }),
    {
      enabled: !!token && isFetchEnabled,
      keepPreviousData: true,
    }
  );
  return { filteredMessages, isFilterLoading, isFilterFetching,  isSuccess};
};

export const useExportMessages = (
  statusInput,
  phoneNumber,
  startDateFormatted,
  endDateFormatted,
  exportFormat,
  isExportEnabled,
  cashedString,
  timezone
) => {
  const {
    data: dataToExport,
    isFetching: isFetchingExport,
    status: exportStatus, 
    error: exportError, 
  } = useQuery(
    [
      `${cashedString}`,
      {
        statusInput,
        phoneNumber,
        startDateFormatted,
        endDateFormatted,
        exportFormat
      },
    ],
    () =>
      userAPI.exportMessages({
        statusInput,
        phoneNumber,
        start: startDateFormatted,
        end: endDateFormatted,
        exportFormat,
        timezone
      }),
    {
      enabled: isExportEnabled,
      refetchInterval: 3000
    }
  );
  return { dataToExport, isFetchingExport, exportStatus, exportError };
};

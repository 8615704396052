export const copyToClipboard = (text) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const textTruncate = (str, length, ending) => {
  let newLength = length;
  let newEnding = ending;
  if (length == null) {
    newLength = 40;
  }
  if (ending == null) {
    newEnding = '';
  }
  if (str.length > newLength) {
    return str.substring(0, newLength - newEnding.length) + newEnding;
  }
  return str;
};

export const calculateSMSCounter = (messageLength) => {
  const SMS_CHARACTER_LIMIT = 160;
  const smsCounter = Math.ceil(messageLength / SMS_CHARACTER_LIMIT);
  return smsCounter;
}

import { useQuery } from 'react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useAcceptInvite = (inviteData, hasInvitation) => {
  const { data, status, error } = useQuery(
    ['acceptInvitation', { token: inviteData.token, orgId: inviteData.orgId }],
  () => userAPI.acceptInvitation({ token: inviteData.token, orgId: inviteData.orgId }), { 
    enabled: hasInvitation 
  });
 
  return { data, status , error };
};

export default useAcceptInvite;

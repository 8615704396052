import React from 'react';
import { Bloc } from 'blocjs';

import Input from './input';

const Inputfield = React.forwardRef(
  ({ name, label, size = 'normal', hint, error, ...rest }, ref) => {
    const hasError = error !== undefined;
    const slugify = (slug, sep) => slug.replace(/\s/g, sep).toLowerCase();
    const slug = slugify(name, '_');
    const uniqInputId = Math.random().toString(26).slice(3);

    return (
      <>
        <Bloc
          my={3}
          fontSize={2}
          fontWeight="bold"
          display="inline-block"
          htmlFor={uniqInputId}
          as="label"
        >
          {label || name}
        </Bloc>
        <Input
          id={uniqInputId}
          name={slug}
          placeholder={name}
          size={size}
          error={error}
          ref={ref}
          {...rest}
        />
        <Bloc
          my={3}
          color={hasError ? 'surfaces.4' : 'surfaces.2'}
          fontSize={1}
          as="p"
        >
          {hasError ? error : hint}
        </Bloc>
      </>
    );
  }
);

export default Inputfield;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Bloc, Flex } from 'blocjs';
import Chevron from '../carets/chevron';
import Text from '../text';
import Avatar from '../avatar';

import withDropdown from '../avatarWithDropdown/withDropdown';

const AvatarWithDropdown = ({ setIsMenuOpen, authUser }) => {
  const [isAvatarMenuOpen, setIsAvatarMenuOpen] = useState(false);
  const [isRotated, setRotate] = useState(false);
  const firstLetterOfUsername = (authUser?.username || '')[0];
  const profileImg = '';

  return (
    <Bloc
      zIndex="999"
      position="relative"
      display="inline-block"
      verticalAlign="middle"
      width="100%"
    >
      <Bloc
        width="100%"
        height={40}
        lineHeight={1.5}
        borderRadius="20px"
        bg="accents.6"
        display="inline-flex"
        justifyContent="space-between"
        alignItems="center"
        px={15}
        style={{ cursor: 'pointer' }}
        onClick={() => { setIsAvatarMenuOpen(!isAvatarMenuOpen); setRotate(!isAvatarMenuOpen); }}
        data-test="avatar-with-caret"
      >
        <Flex alignItems="center">
          <Avatar imageUrl={profileImg} firstLetterOfUsername={firstLetterOfUsername} iconColor="accents.2"/>
          <Bloc mx={3}><Text as="span" variant="normal">{authUser?.username || authUser?.email}</Text></Bloc>
        </Flex>
        <Chevron color="accents.2" rotate={isRotated} />
      </Bloc>
      {isAvatarMenuOpen && (
        <Box position="relative" top="5px" width="100%" right={0} data-test="dropdown">
          <Bloc pt={4} textAlign="right">
            <Link to="/account" data-test="account" onClick={() => setIsMenuOpen(false)}>
              <Text as="span" hoverAsLink variant="normal">
                Account
              </Text>
            </Link>
          </Bloc>
        </Box>
      )}
    </Bloc>
  );
};

export default withDropdown(AvatarWithDropdown);

import React from 'react';
import styled from 'styled-components';
import { Box } from 'blocjs';

const StyledCard = styled(Box)(
  {
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 2px rgb(160 143 143 / 32%)',
    borderRadius: '3px',
    padding: '18px 25px',
    position: 'relative',
  },
);

const Card = ({ children, ...rest }) => (
  <StyledCard {...rest}>
    {children}
  </StyledCard>
);

export default Card;

import { createContext } from 'react';

export const AuthContext = createContext({
  token: null,
  addUserToken: () => {},
  removeUserToken: () => {},
});

export const CurrentUserContext = createContext({
  currentUser: null,
  status: 'idle',
  profile: {}, 
  isProfileLoading: false, 
  isProfileFetching: false,
  authUser: {}
});

export const MessageContext = createContext(null);

import { useQuery } from 'react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useFetchBalance = (token) => {
  const { data, status } = useQuery('userBalance', () => userAPI.getBalance(token));
  return { data, status };
};

export default useFetchBalance;

import React from 'react';
import { Flex, Bloc } from 'blocjs';
import ReactPaginate from 'react-paginate';

import Spinner from '../spinner';

const Pagination = ({
  pageNumber,
  itemsPerPage,
  pages,
  pageCount,
  changePage,
  isFetching,
  isLoading,
}) => {
  let totalMessagesOnPage = itemsPerPage * pageNumber;
  const totalPages = pages?.total || totalMessagesOnPage;
  totalMessagesOnPage =
    totalMessagesOnPage >= totalPages ? totalPages : itemsPerPage * pageNumber;

  return (
    <Bloc ml={[0, '16%']}>
      {pageCount === 0 
      ?
        <Bloc
          as="span"
          fontSize={9}
          color="accents.8"
          style={{ textTransform: 'uppercase' }}>There are no records that match your search.</Bloc>
      :
        <Flex justifyContent="space-between" flexWrap="wrap">
          <Bloc>
            <Bloc
              as="span"
              fontSize={9}
              color="accents.8"
              style={{ textTransform: 'uppercase' }}
            >
              Page {pageNumber} - {totalMessagesOnPage} of {totalPages}
              {(isLoading || isFetching) && (
                <Spinner size="large" style={{ verticalAlign: 'sub' }} />
              )}
            </Bloc>
          </Bloc>
          {!isLoading && (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName="paginationBttns"
              previousLinkClassName="previousBttn"
              nextLinkClassName="nextBttn"
              disabledClassName="paginationDisabled"
              activeClassName="paginationActive"
            />
          )}
        </Flex>
      }
    </Bloc>
  );
};

export default Pagination;

import React, { useContext }  from 'react';
import {
  Link, useLocation, Redirect,
} from 'react-router-dom';
import { Box, Flex, Bloc } from 'blocjs';
import Logo from '../logo';
import Button from '../button';
import AvatarWithDropdown from '../avatarWithDropdown';
import BurgerWithDropdown from './burgerWithDropdown';
import { CurrentUserContext } from '../../contexts';

const Navigation = ({ authenticated = false, uniqueKey, ...rest }) => {
  const location = useLocation();
  const routePath = location.pathname;
  const isRootPath = routePath === '/';
  const isAuthPath = routePath === '/login';
  const isAuthPage = authenticated && !isRootPath;
  const {authUser } = useContext(CurrentUserContext);

  if (isAuthPath && authenticated) {
    return <Redirect to="/messages"/>;
  }

  const AuthenticatedNavItems = () => (
    <>
      <Box as="span" px={3} />
      <Box hide={[true, null]}>
        <AvatarWithDropdown 
          authUser={authUser}
        />
      </Box>
    </>
  );

  const UnAuthenticatedNavItems = () => (
    <Bloc as={Link} to="/login" hide={[true, null]}>
      <Button profile="secondaryTransparent" size="normal">
        Login
      </Button>
    </Bloc>
  );

  return (
    <Bloc
      data-testid="navigation"
      width={1}
      bg={isAuthPage ? "surfaces.0" : "transparent"}
      px="5.55555%"
      boxShadow={isAuthPage ?  "0px 2px 8px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04)" : "none"}
      {...rest}
    >
      <Flex py={4} alignItems="center" paddingTop="15px" paddingBottom="15px">
        <Link to="/">
          <Logo
            size={40}
            bg="primary"
            color="surfaces.0"
            accent="primaries.5"
            borderRadius="50%"
          />
        </Link>
        <Box ml="auto">
          <Box as="span" px={3} />
          {authenticated ? (
            <AuthenticatedNavItems />
          ) : (
            <UnAuthenticatedNavItems />
          )}
        </Box>
        <BurgerWithDropdown
          isAuthenticated={authenticated}
          isAuthPage={isAuthPage}
          uniqueKey={uniqueKey}
          authUser={authUser}
        />
      </Flex>
    </Bloc>
  )

};

export default Navigation;

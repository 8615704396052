import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import posthog from 'posthog-js';

import { AuthContext, CurrentUserContext } from '../../contexts';

const withDropdown = (WrappedComponent) => {
  const NewComponent = (props) => {
    const { push } = useHistory();
    const queryClient = useQueryClient();
    const auth = useContext(AuthContext);
    const { removeUserToken } = auth;
    const { currentUser, status } = useContext(CurrentUserContext);

    const handleLogout = () => {
      queryClient.removeQueries();
      removeUserToken();
      posthog.reset();
      push('/login');
    };

    return (
      <WrappedComponent
        {...props}
        currentUser={currentUser}
        status={status}
        handleLogout={handleLogout}
      />
    );
  };
  return NewComponent;
};

export default withDropdown;

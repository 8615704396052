import React from 'react';
import { Flex, Bloc, Box } from 'blocjs';
import { Layout, Text } from '../../components';

const PageNotFound = () => (
  <Layout pt={[3, '10vh']} data-testid="pageNotFound">
    <Flex
      flexDirection={['column', 'row']}
      justifyContent="space-around"
      alignItems={[null, 'center']}
      maxWidth={['100%']}
      my={10}
    >
      <Bloc mb={0} maxWidth={300}>
        <Text variant="h3" as="h1">
          Page Not Found
        </Text>
        <Bloc width={64} height={2} my={4} bg="accentLight" />
        <Text variant="h5" as="span">
          Instant communication platform for you!
        </Text>
      </Bloc>
      <Bloc
        mt={[4, null]}
        width={[1, 1, 512]}
        boxShadow="0px 0px 2px rgb(160 143 143 / 32%)"
        borderRadius="8px"
        bg="white"
        padding="48px 32px"
      >
        <Bloc display="inline-block">
          You just found yourself in the void, a nudge to your right will lead you to the begining
          {' '}
        </Bloc>
        <Box mt={18} />
        <Text
          color="accents.1"
          fontWeight="normal"
          style={{ whiteSpace: 'nowrap' }}
          href="/"
        >
          Home
        </Text>
      </Bloc>
    </Flex>
  </Layout>
);

export default PageNotFound;

import { createGlobalStyle } from 'styled-components';

import SGRegularWOFF2 from './assets/fonts/SpaceGrotesk-Regular.woff2';
import SGBoldWOFF2 from './assets/fonts/SpaceGrotesk-Bold.woff2';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Space Grotesk";
    font-weight: normal;
    font-style: normal;
    src: local('SpaceGrotesk'),
         local('SpaceGrotesk-Regular'),
         url(${SGRegularWOFF2}?) format("woff2")
  }

  @font-face {
    font-family: "Space Grotesk";
    font-weight: bold;
    font-style: normal;
    src: local('SpaceGrotesk Bold'),
         local('SpaceGrotesk-Bold'),
         url(${SGBoldWOFF2}?) format("woff2")
  }

  html, body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    background: ${(props) => props.theme.colors.lightAccent};
    font-family: ${(props) => props.theme.fonts.sans};
    color: ${(props) => props.theme.colors.surfaces[3]};
    margin: 0;
  }

  a {
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
  }

  #root {
    position: relative;
    min-height: 100vh;
  }

  button {
    white-space: nowrap;
  }

  input, textarea {
    background: transparent;
    font-family: 'Space Grotesk',sans-serif;
    outline: none;
  }

  input:disabled {
    background: #fafafa;
  }

  input::placeholder {
    color: #DFDBDB;
  }

  thead td {
    text-transform: uppercase;
    color: #3E484D;
    font-weight: bold;
    font-size: 9px;
    padding: 0 25px;
  }

  tbody td {
    font-size: 12px;
    color: #757F84;
    padding: 0 25px;
    white-space: nowrap;
  }

  tbody tr:hover {
    background-color: #E4FFFC;
  }

  .not-selectable tbody tr:hover {
    background-color: transparent;
  }

  tbody tr:last-child {
    border-bottom: none;
  }

  h5 {
    line-height: initial !important
  }

  .place:nth-child(1) {
    animation-name: fade;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-direction: alternate-reverse;  
  }

  .place:nth-child(2) {
    animation-name: fade;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-direction: alternate;
  }

  @keyframes fade{
    0%,45% {
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }

  .paginationBttns {
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .paginationBttns a {
    padding: 0px;
    margin: 4px;
    border-radius: 5px;
    color: #757F84;
    cursor: pointer;
    font-size: 9px;
  }

  .paginationBttns .previousBttn, .paginationBttns .nextBttn {
    border-bottom: 1px solid #8EF3E7;
    border-radius: 0;
    color: #0BAF9C;
    font-size: 12px;
  }

  .paginationBttns .previousBttn {
     margin-right: 16px;
  }

  .paginationBttns .nextBttn {
    margin-left: 16px;
  }

  .paginationActive a {
    color: #FFF9F9;
    padding: 4px 8px;
    background-color: #16C1AD;
  }

  .paginationDisabled .previousBttn, 
  .paginationDisabled .nextBttn {
    color: #BEC1C2;
    border-bottom: 1px solid #BEC1C2;
  }

  a.previousBttn {
      margin-left: 0;
  }

  .react-datepicker {
    font-family: 'Space Grotesk',sans-serif;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container input {
    border: 1px solid;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    font-family: 'Space Grotesk',sans-serif;
    padding: 0 10px;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    color: #3E484D;
  }

  .dots-btn:hover {
    background: #16C1AD;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 32%);
    border-radius: 8px;
    text-align: center;
    line-height: 0px;
    border: none;
  }

  .dot {
    width: 4px;
    height: 4px;
    background: #EEECEC;
    border-radius: 50%;
    display: inline-block;
    margin: 0px 4px;
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      background-color: #19c1ac;
  }

  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
      border-radius: 0.3rem;
      background-color: #19c1ac;
      color: #fff;
  }

  .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: #e4213a;
  }

  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: #e4213a;
  }

  .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
      background-color: #19c1ac;
  }

  .rc-mentions {
    display: inline-block;
    position: relative;
    white-space: pre-wrap;
    overflow: hidden;
  }

  .rc-mentions textarea {
    width: 100%;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: normal;
    border-radius: 4px;
    border: 1px solid rgb(211, 211, 211);
    overflow: hidden;
    resize: none;
    padding: 9px;
    outline: 0px;
    box-sizing: border-box;
    &::placeholder {
      color: #dedbdb;
      font-size: 14px;
    }
  }

  .rc-mentions.textError textarea {
    border-color: #EC0000;
  }
  
  
  .rc-mentions-measure {
    position: absolute;
    left: 0;
    right: 0px;
    top: 0;
    bottom: 0;
    pointer-events: none;
    color: transparent;
    z-index: -1;
  }
  
  .rc-mentions-dropdown {
    position: absolute;
  }

  .rc-mentions-dropdown-menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .rc-mentions-dropdown-menu-item {
    cursor: pointer;
  }
  
  .rc-mentions-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-size: 14px;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    margin-top: 5px;
    margin-left: 5px;
  }
  
  .rc-mentions-dropdown-menu-item {
    padding: 4px 8px;
  }
  
  .rc-mentions-dropdown-menu-item-active {
    background: #E4FFFC;
  }
  
  .rc-mentions-dropdown-menu-item-disabled {
    opacity: 0.5;
  }

  .google-login {
    width: 100%;
    justify-content: center;
  }

  .google-login span {
    font-weight: 700 !important;
    font-family: 'Space Grotesk';
    font-size: 16px;
  }
 
  .reactEasyCrop_CropArea {
    color: rgba(250, 250, 250, 0.73) !important;
    border: 2px solid #16c1ad !important;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .toggle-switch {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .toggle-switch-label:focus {
      outline: none;
  }
  .toggle-switch-label:focus > span {
      box-shadow: 0 0 2px 5px red;
  }
  .toggle-switch-label > span:focus {
      outline: none;
  }
  .toggle-switch-inner:before, .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 34px;
    padding: 0;
    line-height: 34px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
  }
  .toggle-switch-inner:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #16C1AD;
    color: #fff;
  }
  .toggle-switch-disabled {
      cursor: not-allowed;
  }
  .toggle-switch-disabled:before {
      cursor: not-allowed;
  }
  .toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #757F84;
    color: #fff;
    text-align: right;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
      margin-left: 0;
  }
  .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
      right: 0px;
  }
  .toggle-switch.small-switch {
      width: 40px;
  }
  .toggle-switch.small-switch .toggle-switch-inner:after, .toggle-switch.small-switch .toggle-switch-inner:before {
    content: "";
    height: 20px;
    line-height: 20px;
  }
  .toggle-switch.small-switch .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
  }
  @media screen and (max-width: 991px) {
    .toggle-switch {
      transform: scale(0.9);
    }
  }
  @media screen and (max-width: 767px) {
    .toggle-switch {
      transform: scale(0.825);
    }
  }
  @media screen and (max-width: 575px) {
    .toggle-switch {
      transform: scale(0.75);
    }
  }

  .countryItem:hover {
    background: #E4FFFC;
  }
`;

export default GlobalStyle;

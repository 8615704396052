import { useQuery } from 'react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

export const useFetchBilling = (pageNumber, itemsPerPage) => {
  const { data, status, error, isFetching, isLoading, isSuccess } = useQuery(
    ['userBillingHistory', { pageNumber, itemsPerPage }],
    () => userAPI.getBillingHistory({ pageNumber, itemsPerPage }),
    { keepPreviousData: true }
  );
  return { data, status, error, isFetching, isLoading, isSuccess };
};

export const useFetchTransactions = (start, end, isEnabled) => {
  const { data: transactionsData, status: transactionsStatus, error: transactionsError, 
      isSuccess: isTransactionsSuccess, isFetching: isFetchingTransactions, 
      isLoading: isLoadingTransactions } = useQuery(
    ['userTransactions', { start, end }],
    () => userAPI.getTransactions({ start, end }),
    { keepPreviousData: true, enabled: isEnabled}
  );
  return { transactionsData, transactionsStatus, isTransactionsSuccess, transactionsError, isFetchingTransactions, isLoadingTransactions };
};


export default useFetchBilling;

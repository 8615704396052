import { useQuery } from 'react-query';
import UserAPI from '../services';
import getUserLocation from '../services/thirdParty/location';

const userAPI = new UserAPI();

export const useFetchUserSelf = (token) => {
    const { data, status } = useQuery(['currentUser', token], () => userAPI.getCurrentUser(token), 
        {
            keepPreviousData: true,
            enabled: !!token,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
        },
    );
    return { data, status };
};

export const useFetchUserProfile = (token) => {
    const { data: profileData, isLoading: isProfileLoading, isFetching: isProfileFetching } = 
    useQuery(['selfProfile', token], () => userAPI.getSelfProfile(token), { keepPreviousData: true, enabled: !!token });
    return { profileData, isProfileLoading, isProfileFetching };
};

export const useFetchUserLocation = () => {
    const { data, status, isLoading }  = useQuery('userLocation', () => getUserLocation());
    return { data, status, isLoading };
}
import React from 'react';
import { Box, Bloc } from 'blocjs';
import Caret from '../carets/caret';
import Text from '../text';
import withDropdown from './withDropdown';

const Dropdown = ({ handleLogout }) => (
  <Box position="absolute" top="65px" minWidth={152} width="auto" right={0}>
    <Caret
      size={16}
      direction="up"
      position="absolute"
      top="-15px"
      right="17px"
    />
    <Bloc
      bg="surfaces.0"
      minWidth={152}
      width="auto"
      borderRadius={2}
      boxShadow="0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16), 0px 16px 32px rgba(160, 143, 143, 0.08), 0px 32px 64px rgba(160, 143, 143, 0.16);"
    >
      <Bloc py={4} px="20px" textAlign="right">
        <Text
          as="span"
          hoverAsLink
          variant="normal"
          onClick={handleLogout}
          style={{ cursor: 'pointer' }}
          data-test="logout"
        >
          Logout
        </Text>
      </Bloc>
    </Bloc>
  </Box>
);

export default withDropdown(Dropdown);

import { createElement } from 'react';
import { typography, variant, compose, color, flexbox } from 'styled-system';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const BaseText = ({
  as,
  href,
  link,
  uppercase,
  hoverAsLink,
  variant: variantProp = 'span',
  ...props
}) => {
  let renderedAs = as || variantProp;

  if (href) {
    renderedAs = 'a';
  }

  return createElement(renderedAs, { ...props, href });
};

const styleLinks = ({ href, as, link, hoverAsLink, theme }) => {
  const isLink = href || link || as === 'a' || as === Link;

  if (hoverAsLink) {
    return css`
      :hover {
        cursor: 'pointer';
        font-weight: 700;
        color: ${theme.colors.accent};
      }
    `;
  }

  return (
    isLink && {
      borderBottom: '1px solid',
      borderBottomColor: theme.colors.accentLight,
      cursor: 'pointer',
      fontWeight: 700,
    }
  );
};

const Text = styled(BaseText)(
  {
    position: 'relative',
    color: '#3E484D',
    margin: 0,
  },
  ({ uppercase }) =>
    uppercase && {
      textTransform: 'uppercase',
    },
  ({ theme }) => variant({ variants: theme.typography }),
  styleLinks,
  compose(typography, color, flexbox)
);

export default Text;

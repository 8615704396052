import React from 'react';
import ReactDOM from 'react-dom';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import posthog from 'posthog-js';

import App from './App';
import * as serviceWorker from './serviceWorker';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
  },
});

posthog.init('phc_Cn4ac70wVaqcPfTZfJri78MBdpYM8nGoAqcdxoIETBQ', { 
  api_host: 'https://app.posthog.com'
});


ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

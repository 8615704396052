import React from 'react';
import { useTable, useFilters, useSortBy } from 'react-table';
import { Bloc, Flex } from 'blocjs';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import Icon from '../icon';

const Table = ({
  columns,
  data,
  onCheckBox,
  onRowClick,
  setRowBackgroundColor,
  setCheckbox,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useSortBy
    );

  const sortColumnIcon = (isColumnSorted, isColumnSortedDesc) => {
    if (isColumnSorted) {
      if (isColumnSortedDesc) {
        return <Icon name="accordion" color="accents.8" size={16} dimension="24x24" />;
      }
      return (
        <Icon
          name="accordion"
          color="accents.8"
          size={16} dimension="24x24"
          style={{
            transform: 'rotate(180deg) translateX(0px) translateY(2px)',
          }}
        />
      );
    }
    return <Icon name="accordion" color="accents.8" size={16} dimension="24x24"/>;
  };

  return (
    <Bloc
      ml={[0, '16%']}
      boxShadow="0px 0px 2px rgba(0, 0, 0, 0.32)"
      borderRadius={3}
      paddding={0}
      bg="surfaces.0"
      overflowX="auto"
      mb={15}
    >
      <Bloc
        as="table"
        width="100%"
        style={{ borderCollapse: 'collapse' }}
        {...getTableProps()}
      >
        <Bloc as="thead">
          {headerGroups.map((headerGroup) => (
            <Bloc
              key={headerGroup}
              as="tr"
              height="30"
              fontWeight="bold"
              textAlign="left"
              borderBottom="1px solid #DFDBDB"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <Bloc
                  key={column.id}
                  as="td"
                  py={3}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  data-testid={column.render('Header')}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <Flex alignItems='center'>
                    {column.render('Header')}
                    <Bloc as="span" ml={3}>
                      {sortColumnIcon(column.isSorted, column.isSortedDesc)}
                    </Bloc>
                  </Flex>
                </Bloc>
              ))}
              <Bloc as="td" py={3} textAlign="right">
                Selected
              </Bloc>
            </Bloc>
          ))}
        </Bloc>
        <Bloc as="tbody" {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Bloc
                key={row.id + row.original.id}
                as="tr"
                textAlign="left"
                borderBottom="1px solid #DFDBDB"
                background={setRowBackgroundColor(row.original.id)}
                {...row.getRowProps({
                  style: { cursor: 'pointer' },
                  onClick: (e) => onRowClick(row, e),
                })}
                data-test="data-row"
                data-testid="data-row"
              >
                {row.cells.map((cell) => (
                  <Bloc as="td" py={3} {...cell.getCellProps()} key={cell.column.id}>
                    {cell.render('Cell')}
                  </Bloc>
                ))}
                <Bloc as="td" py={3} onClick={(e) => e.stopPropagation()} data-test="check-col">
                  <Bloc
                    as="span"
                    style={{ cursor: 'pointer', float: 'right' }}
                    data-test="check-box"
                    data-testid="check-box"
                    onClick={(e) => onCheckBox(row.original, e)}
                  >
                    {setCheckbox(row.original.id)}
                  </Bloc>
                </Bloc>
              </Bloc>
            );
          })}
        </Bloc>
      </Bloc>
    </Bloc>
  );
};

export default Table;

import React, { useEffect, useState } from 'react';
import { Bloc } from 'blocjs';
import Icon from '../icon';
import Text from '../text';


const Toast = ({ message, status, closed = false, timeOut = 5000 }) => {
  let bg = ''; 
  let color = '';
  if (status === 'error') {
    bg = 'primaries.3';
    color = 'surfaces.0';
  } else if (status === 'success') {
    bg = 'accentDark';
    color = 'surfaces.0';
  } else if (status === 'warning') {
    bg = 'warning';
    color = 'surfaces.3';
  }

  const capitalize = (str) => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  };

  const [showToast, setShowToast] = useState(true);

  useEffect(() => {
    if(closed) {
      const timeId = setTimeout(() => {
        setShowToast(false)
      }, timeOut)
      return () => {
        clearTimeout(timeId);
      }
    }
    return true
  }, [closed, timeOut]);

  if (!showToast) {
      return null;
  }


  return (
    <Bloc
      position="fixed"
      zIndex={9999}
    >
      <Bloc
        as="button"
        bg={bg}
        boxShadow="0px 0px 2px rgba(160, 143, 143, 0.32), 0px 8px 16px rgba(160, 143, 143, 0.16)"
        minHeight="40px"
        borderRadius={24}
        fontStyle="normal"
        fontWeight="bold"
        padding="13px 24px"
        position="absolute"
        display="flex"
        justifyContent="center"
        border="none"
        outline="none"
        style={{
          position: 'fixed',
          left: '50%',
          top: '88px',
          transform: 'translate(-50%, 0px)',
          transition: 'transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
          transformOrigin: 'top left',
        }}
        maxWidth="80%"
      >
        <Icon name="twilight" color={color} size={12} />
        <Text
          variant="h6"
          color={color}
          as="span"
          fontSize="12px"
          lineHeight="15px"
          style={{ marginLeft: '8px', overflow: 'auto' }}
        >
          {' '}
          {capitalize(message)}
          {' '}
        </Text>
      </Bloc>
    </Bloc>
  );
};

export default Toast;

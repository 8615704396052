import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import Spinner from '../spinner';

const StyledButton = styled('button')(
  {
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontWeight: 'bold',
    outline: 'none',
    textTransform: 'uppercase'
  },
  variant({
    prop: 'profile',
    scale: 'buttons',
    variants: {
      primary: {
        color: 'surface',
        bg: 'primary',
      },
      accent: {
        color: 'surface',
        bg: 'accentDark',
      },
      warning: {
        color: 'surface',
        bg: 'warning',
      },
    },
  }),
  variant({
    prop: 'size',
    variants: {
      large: {
        fontSize: 14,
        borderRadius: 6,
        px: 6,
        py: 4,
      },
      normal: {
        fontSize: 1,
        borderRadius: 5,
        px: '20px',
        py: '12px',
      },
      small: {
        fontSize: 0,
        borderRadius: 4,
        px: 5,
        py: '10px',
      },
    },
  })
);

const Button = ({ children, loading = false, ...rest }) => {
  const getSpinner = (isLoading) =>
    isLoading && <Spinner size={rest.size} color="primaryLight" />;
  return (
    <StyledButton disabled={loading} {...rest}>
      {children}
      {getSpinner(loading)}
    </StyledButton>
  );
};

export default Button;

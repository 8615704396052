import { useQuery } from 'react-query';
import UserAPI from '../services';

const userAPI = new UserAPI();

const useFetchPublishableKey = (token) => {
  const { data, isLoading: isPublishableKeyLoading } = useQuery(['publishable_key', token], () => userAPI.getPublishableKey(token), {
    keepPreviousData: true, enabled: !!token
  });

  return { data, isPublishableKeyLoading };
};

export default useFetchPublishableKey;

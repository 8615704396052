import React from 'react';
import { Bloc } from 'blocjs';
import ProgressiveImage from "react-progressive-graceful-image";

import Icon from '../icon';


const Avatar = ({ imageUrl, iconColor }) => (
  <Bloc width={24} height={24} textAlign="center">
    <ProgressiveImage src={imageUrl}>
      {(src, loading) => (
        loading ?  (
          <Bloc as="span" color="accents.8" mb="3px">
            <Icon name="user" color={iconColor} size={12} />
          </Bloc>
        )
        : (
          <Bloc
            as="img"
            width={24}
            height={24}
            borderRadius="50%"
            style={{ objectFit: "cover" }}
            src={src}
            alt="avatar"
          />
      ))}
    </ProgressiveImage>
 </Bloc>
);

export default Avatar;

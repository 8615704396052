import React from 'react';
import { Bloc } from 'blocjs';

const Layout = ({ children, ...rest }) => (
  <Bloc px={[5, 10, 10, 0]} pt={64} width="100%" maxWidth={1130} margin="0 auto" {...rest}>
    {children}
  </Bloc>
);

export default Layout;
